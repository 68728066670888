/* General Styling */
body {
  font-family: Arial, sans-serif;
  background-color: #f7f9fc;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Header */
.app-header {
  background-color: #4a90e2;
  color: #fff;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-info p {
  margin: 0;
}

button {
  background-color: #2a72d4;
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
}

button:hover {
  background-color: #1d5bbf;
}

/* Main Content */
.main-container {
  display: flex;
  flex-direction: column;
  padding: 2em;
  max-width: 1200px;
  margin: auto;
}

/* Status Panel */
.status-panel {
  display: flex;
  justify-content: space-around;
  background-color: #eef3f8;
  padding: 1em;
  border-radius: 8px;
  margin-bottom: 2em;
}

.sync-info p {
  margin: 0;
  color: #555;
}

.sync-info button {
  margin-top: 0.5em;
  background-color: #4caf50;
}

/* Calendar Section */
.calendar-section h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #333;
}

.calendar-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
}

.calendar-card {
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1em;
  flex: 1 1 300px;
  max-width: 300px;
}

.calendar-card h3 {
  margin: 0;
  color: #4a90e2;
}

.record-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  font-size: 0.9em;
  color: #666;
}

.progress-bar {
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin: 1em 0;
}

/* Log Panel */
.log-panel {
  margin-top: 2em;
  padding: 1em;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.logs {
  max-height: 200px;
  overflow-y: auto;
}

/* Footer */
.footer {
  background-color: #f1f3f5;
  color: #666;
  text-align: center;
  padding: 1em;
  font-size: 0.9em;
  margin-top: 2em;
}